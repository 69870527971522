<template>
  <div class="d-flex justify-content-center align-items-center" style="height:100vh">
    <div class="col-10 py-4 px-3" style="">
    <form @submit.prevent="submit()">
      <div class="row text-center mb-3">
        <h4>Scan Bourse Internationale de Paris 2022</h4>
      </div>
      <div class="row">
        <div class="col-12 form-floating mb-2">
          <input type="email" v-model="email" id="mailinput" class="form-control" placeholder="eMail">
          <label for="mailinput">Email</label>
        </div>
        <div class="col-12 form-floating mb-3">
          <input type="password" v-model="password" id="passwordinput" class="form-control" placeholder=
          "passWord">
          <label for="passwordinput">Mot de passe</label>
        </div>        
        <div class="col-12 mb-2">
          <button type="submit" class="btn btn-sofcot float-end">Connexion</button>
        </div>
        <div v-if="errorMessage" class="col-12 mt-3">
          <p class="error">{{errorMessage}}</p>
        </div>
      </div>
    </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  props: {
    errorMessage: {
      type: String,
      default: "",
    },
  },
  methods: {
    submit() {
      this.$emit("submit", {
        email: this.email,
        password: this.password,
      });
    },
  },
};
</script>
<style scoped>

</style>